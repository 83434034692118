import { callApi } from "../../../api"

const DEMO_INITIALISE_API = "demo/initialise"
const DEMO_AUTHENTICATE_API = "demo/authenticate"
const DEMO_VALIDATE_EMAIL_API = "demo/validate/email"
const DEMO_Disposable_EMAIL_API = "https://disposable.debounce.io/"
const DEMO_VALIDATE_PHONE_API = "demo/validate/phone"
const DEMO_RESEND_OTP_API = "demo/resend-otp"

/**
 * @typedef {import("../integration-experience/actions").LoginResponse} LoginResponse
 */

/**
 * Checks if the **email** has an existing account, else sends an OTP to the given email.
 * @param {string} appId The **appId** of the app to which the user was invited.
 * @param {string} email The **email** of the user.
 * @returns {Promise<LoginResponse>} Returns a promise that resolves to {@link LoginResponse}.
 */
export function demoInitialiseAPI(email) {
  const config = {
    method: "POST",
  }

  const onSuccessCallback = (json) => {
    if (json.status === "success" && json.data?.exists) {
      const date = new Date(json?.data?.user?.created_at)
      date.setDate(date.getDate() + json?.data?.user?.demo_period)
      const demo_period_end_date = date.toUTCString()

      return {
        token: "1234-5678-9101-1213",
        user: {
          name: json?.data?.user?.name,
          job_title: json?.data?.user?.job_title,
          email: json?.data?.user?.customer_id,
          access_code: json?.data?.user?.access_code,
          demo_period_end_date,
          phone: json?.data?.user?.phone,
        },
      }
    } else if (json.status === "success" && !json.data?.exists) {
      return {
        message: "A 6-digit OTP is sent to your work email",
      }
    }
  }
  const onFailureCallback = (response) => {
    return response
  }

  return callApi(
    `${DEMO_INITIALISE_API}?customerId=${email}`,
    config,
    onSuccessCallback,
    onFailureCallback,
  )
}

/**
 * Logs the user in by the given **access_code**.
 * @param {string} name The **name** of the user.
 * @param {string} job_title The **job_title** of the user.
 * @param {string} email The **email** of the user.
 * @param {string} otp The **otp** received by the user via an email.
 * @returns {Promise<LoginResponse>} Returns a promise that resolves to {@link LoginResponse}.
 */
export function demoAuthenticateAPI(
  name,
  job_title,
  phone,
  country_code,
  email,
  otp,
) {
  const config = {
    method: "POST",
    body: JSON.stringify({
      name,
      job_title,
      phone: `${country_code} ${phone}`,
      email_id: email,
      otp,
    }),
  }

  const onSuccessCallback = (json) => {
    if (json.status === "success") {
      const date = new Date(json?.data?.user?.created_at)
      date.setDate(date.getDate() + json?.data?.user?.demo_period)
      const demo_period_end_date = date.toUTCString()

      return {
        token: "1234-5678-9101-1213",
        user: {
          name: json?.data?.user?.name,
          job_title: json?.data?.user?.job_title,
          phone: json?.data.user?.phone,
          email: json?.data?.user?.customer_id,
          access_code: json?.data?.user?.access_code,
          demo_period_end_date,
        },
      }
    } else {
      return {
        message: json?.message,
      }
    }
  }

  const onFailureCallback = (response) => {
    return response
  }

  return callApi(
    `${DEMO_AUTHENTICATE_API}`,
    config,
    onSuccessCallback,
    onFailureCallback,
  )
}

/**
 * Validates if the given **email** if valid.
 * @param {string} email The **email** of the user.
 * @returns {Promise<boolean>} Returns a promise to **true** if the email is valid, else **false**.
 */
export function demoValidateEmailAPI(email) {
  const config = {
    method: "POST",
  }

  const onSuccessCallback = (json) => {
    return json
  }

  const onFailureCallback = (response) => {
    return response
  }

  return callApi(
    `${DEMO_VALIDATE_EMAIL_API}?email=${email}`,
    config,
    onSuccessCallback,
    onFailureCallback,
  )
}

/**
 * Validates if the given **email** if Disposalble ( disposable means an temporary email ).
 * @param {string} email The **email** of the user.
 * @returns {Promise<boolean>} Returns a promise to **true** if the email is disposable, else **false** ( if false then accept the email id ).
 */
export async function demoDisposableEmailAPI(email) {
  const apiUrl = `${DEMO_Disposable_EMAIL_API}?email=${email}`

  try {
    const disposableResponse = await fetch(apiUrl)
    if (disposableResponse.ok) {
      const disposalbleData = await disposableResponse.json()
      return JSON.parse(disposalbleData.disposable)
    } else {
      throw new Error(
        "Request failed with status: " + disposableResponse.status,
      )
    }
  } catch (error) {
    console.error("Error fetching data:", error)
    return null
  }
}

export function demoValidatePhoneNumberAPI(phoneNumber) {
  //console.log("Validate Phone Number API !!")
}

/**
 * Resends a new OTP to the given **email**.
 * @param {string} email The **email** of the user.
 * @returns {Promise<boolean>} Returns a promise to **true** if the OTP is resent, else **false**.
 */
export function demoResendOtpAPI(email) {
  const config = {
    method: "POST",
  }

  const onSuccessCallback = (json) => {
    return json
  }

  const onFailureCallback = (response) => {
    return response
  }

  return callApi(
    `${DEMO_RESEND_OTP_API}?customerId=${email}`,
    config,
    onSuccessCallback,
    onFailureCallback,
  )
}
