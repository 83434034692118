import { Box, Button, Typography } from "@material-ui/core";
import CheckOutlinedIcon from "@material-ui/icons/CheckOutlined";
import React from "react";
import { useAuth } from "../../../../../../contexts/AuthContext";
import CustomMaterialUIDialog from "../../../../../ReusableComponents/CustomMaterialUIDialog";
import { demoSlackMessagerIntegrate } from "../../actions";

export default function DemoPeriodOverDialog({ open }) {
  const auth = useAuth()

  return (
    <CustomMaterialUIDialog
      openFlag={open}
      onClose={() => {}}
      onDialogClose={() => {}}
      isFullWidth={false}
      hideCloseIcon={true}
      maxWidth="sm"
      dialogContent={
        <Box
          style={{
            maxWidth: "413px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            color: "#002845",
            padding: "28px 36px",
            textAlign: "center",
            paddingTop: "0px",
          }}
        >
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "#469702",
              borderRadius: "50%",
              width: "64px",
              height: "64px",
              marginBottom: "17px",
            }}
          >
            <CheckOutlinedIcon
              style={{
                color: "#ffffff",
                fontSize: "44px",
              }}
            />
          </Box>
          <Typography
            style={{
              fontWeight: "700",
              fontSize: "22px",
              marginBottom: "12px",
            }}
          >
            Your demo period is over
          </Typography>
          <Typography
            style={{
              fontWeight: "500",
              fontSize: "14px",
            }}
          >
            You can try nudges on your app by integrating Apxor. Avail our 14
            days free trail now!
          </Typography>
        </Box>
      }
      dialogActions={
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "28px 36px",
            paddingTop: "0px",
          }}
        >
          <a
            href="https://www.apxor.com/sign-up"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={()=>{ demoSlackMessagerIntegrate(auth.user) }}
              style={{
                fontSize: "16px",
                fontWeight: "700",
              }}
            >
              Integrate My App
            </Button>
          </a>
        </Box>
      }
      dialogActionStyles={{
        justifyContent: "center",
        alignItems: "center",
      }}
    />
  );
}
