import { Button, ButtonBase, Grid, Typography } from "@material-ui/core"
import Radio from "@material-ui/core/Radio"
import { withStyles } from "@material-ui/core/styles"
import CloseIcon from "@material-ui/icons/Close"
import Apxor from "apxor"
import React, { useState } from "react"
import CreatableSelect from "react-select/creatable"
import { APXOR_SDK_CATEGORY_CONSTANTS } from "../../../../../../constants"
import ActionsTextField from "../../../../../ReusableComponents/ActionsTextField"
import ClassicCard from "../../../../../ReusableComponents/ClassicCard"
import RowDiv from "../../../../../ReusableComponents/RowDiv"
import android from "../../assets/android.png"
import ios from "../../assets/ios.png"
import web from "../../assets/web.png"
import { useTracked } from "../store"

const APP_PLATFORMS = {
  ios: "ios",
  android: "android",
  web: "web",
}

const APP_PLATFORM_NAMES = {
  ios: "IOS",
  android: "Android",
  web: "Web",
}

const APP_PLATFORM_IMAGES = {
  ios: (
    <img alt="" style={{ height: 29, width: 24, marginBottom: -4 }} src={ios} />
  ),
  android: (
    <img
      alt=""
      style={{ height: 30, width: 28, marginBottom: -4 }}
      src={android}
    />
  ),
  web: (
    <img alt="" style={{ height: 36, width: 36, marginBottom: -4 }} src={web} />
  ),
}

const getDefaultPlatform = (appPlatform, metaPlatform) => {
  if (appPlatform === APP_PLATFORMS.ios) return APP_PLATFORMS.ios
  else if (appPlatform === APP_PLATFORMS.web) return APP_PLATFORMS.web
  else return metaPlatform
}

function filterPlatforms(platforms, appPlatform, actionType) {
  return appPlatform === "omni" && actionType === "Survey"
    ? platforms.filter((platform) => platform !== "web")
    : platforms
}

export default function CreateEditCampaignDialog({
  handleDone,
  metaPlatform,
  appPlatform,
  handleCancel,
  task,
  tagNames,
  loading,
  title,
}) {
  const [state] = useTracked()
  const {
    config: {
      meta: { name, tags = [], experience_type = "" },
    },
  } = state

  const ORadio = withStyles({
    root: {
      "&$checked": {
        color: "#FF7F33",
      },
    },
    checked: {},
  })((props) => <Radio color="default" {...props} />)

  const customStyles = {
    control: (base) => ({
      ...base,
      minHeight: "3em",
      borderRadius: 2,
      primary: "orange",
      boxShadow: "none",
      border: "1px solid #CCD4DA",
      "&:hover": { borderColor: "none" }, // border style on hover
    }),
    "& .MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover": {
      backgroundColor: "transparent",
    },
    option: (base, state) => ({
      ...base,
      backgroundColor: state.isFocused ? "#F8F8F8" : "#FFFFFF",
      color: state.isFocused ? "#FF7F33" : "black",
      cursor: "pointer",
      borderLeft: state.isFocused ? "3px solid #FF7F33" : "none",
    }),
  }

  const typographyStyle = {
    fontFamily: "Manrope",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "19px",
    color: "#667A8A",
    mixBlendMode: "normal",
  }

  const [campaignName, setCampaignName] = useState(name)
  const [selectedPlatform, setSelectedPlatform] = useState(
    getDefaultPlatform(appPlatform, metaPlatform),
  )
  const [tagValue, setTagValue] = useState(tags ?? [])

  return (
    <div
      style={{
        marginTop: "0.5em",
        marginBottom: "0.5em",
        marginLeft: "0.5em",
        marginRight: "0.5em",
        paddingBottom: 40,
      }}
    >
      <div
        style={{
          position: "inline-block",
          float: "right",
          cursor: "pointer",
        }}
        onClick={handleCancel}
      >
        {" "}
        <CloseIcon />
      </div>
      <Typography
        variant="h4"
        style={{
          fontWeight: "bold",
          fontFamily: "Manrope",
          fontStyle: "normal",
          fontSize: "28px",
          lineHeight: "38px",
          marginBottom: "1em",
          color: "#002845",
        }}
      >
        {title} Details
      </Typography>

      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography style={typographyStyle}>Name</Typography>
          <ActionsTextField
            focused
            style={{
              width: "100%",
              marginTop: 4,
              marginBottom: 4,
              height: 44,
            }}
            value={campaignName}
            onChange={(e) => setCampaignName(e.target.value)}
          />
        </Grid>

        <div>
          <Typography
            style={{
              ...typographyStyle,
              marginLeft: "1.1em",
              marginTop: "0.5em",
            }}
          >
            Platform
          </Typography>
          {filterPlatforms(Object.keys(APP_PLATFORMS), appPlatform, title).map(
            (platformKey) => {
              return (
                <div
                  item
                  key={"select-card-" + platformKey}
                  style={{
                    display: "inline-block",
                    position: "relative",
                    marginTop: "5px",
                    marginLeft: 15,
                    alignItems: "left",
                  }}
                >
                  <ButtonBase
                    style={{
                      borderRadius: 2,
                      width: "170px",
                      height: "68px",
                    }}
                    disabled={appPlatform !== "omni"}
                    disableRipple
                    disableTouchRipple
                    onClick={() => setSelectedPlatform(platformKey)}
                  >
                    <ClassicCard
                      style={{ minWidth: 64, boxShadow: "none" }}
                      selected={selectedPlatform === platformKey}
                      disabled={appPlatform !== "omni"}
                    >
                      <RowDiv>
                        <ORadio
                          checked={selectedPlatform === platformKey}
                          style={{ marginBottom: -8 }}
                        />
                        {APP_PLATFORM_IMAGES[platformKey]}
                        <Typography
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            fontWeight: 600,
                            fontFamily: "Manrope",
                            fontStyle: "normal",
                            fontSize: "16px",
                            lineHeight: "22px",
                            color: "#002845",
                            marginLeft: 12,
                          }}
                        >
                          {APP_PLATFORM_NAMES[platformKey]}
                        </Typography>
                      </RowDiv>
                    </ClassicCard>
                  </ButtonBase>
                </div>
              )
            },
          )}
        </div>
        <Grid item xs={12}>
          <Typography
            style={{
              ...typographyStyle,
              marginBottom: "0.4em",
              marginTop: "1em",
            }}
          >
            Tags
          </Typography>

          <CreatableSelect
            placeholder="Categorise your campaigns based on tags, ex: 'offers', 'services'"
            isLoading={loading}
            maxMenuHeight={90}
            isClearable
            options={tagNames.map((o) => ({
              label: o,
              value: o,
            }))}
            isMulti
            onChange={(e) => setTagValue(!e ? [] : e.map((tag) => tag.label))}
            value={tagValue.map((tag) => ({
              label: tag,
              value: tag,
            }))}
            styles={customStyles}
            formatCreateLabel={(inputText) => {
              return (
                <div
                  style={{
                    fontFamily: "Manrope",
                    fontStyle: "normal",
                    fontWeight: 600,
                    fontSize: "16px",
                    lineHeight: "22px",
                    color: "#FF7F33",
                    left: 32,
                  }}
                >
                  Add & Select this Tag
                </div>
              )
            }}
          />
        </Grid>

        <RowDiv
          center
          styles={{
            justifyContent: "flex-end",
            width: "100%",
            marginRight: 16,
            marginTop: 30,
          }}
        >
          <Button
            color={"primary"}
            style={{
              marginRight: "2em",
              width: 54,
              height: 22,
              color: "#002845",
            }}
            onClick={handleCancel}
          >
            Cancel
          </Button>

          <Button
            id="campaign-create-button"
            style={{ width: "140px", height: "44px" }}
            variant={"contained"}
            color={"primary"}
            disabled={
              selectedPlatform === null ||
              campaignName === "" ||
              tagValue === []
            }
            onClick={() => {
              handleDone(campaignName, selectedPlatform, tagValue)
              Apxor.logEvent(
                "CreateNewCampaignClicked",
                { name: campaignName, experience_type: experience_type },
                APXOR_SDK_CATEGORY_CONSTANTS.CAMPAIGNS,
              )
            }}
          >
            {task}
          </Button>
        </RowDiv>
      </Grid>
    </div>
  )
}
