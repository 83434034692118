import {
  Box,
  Breadcrumbs,
  Button,
  IconButton,
  Typography,
  makeStyles,
} from "@material-ui/core"
import { ChevronLeft, ChevronRight, CreateOutlined } from "@material-ui/icons"
import CloseIcon from "@material-ui/icons/Close"
import Apxor from "apxor"
import deepEqual from "deep-equal"
import moment from "moment"
import React, { useEffect, useRef, useState } from "react"
import { Prompt, useHistory, useRouteMatch } from "react-router-dom"
import { isExperienceDashboard } from "../../../../../../config"
import {
  ACTIONS_ENUM,
  APXOR_SDK_CATEGORY_CONSTANTS,
  FEATURE_KEY_ENUM,
} from "../../../../../../constants"
import { useAuth } from "../../../../../../contexts/AuthContext"
import { isAccessable } from "../../../../../../utils"
import useMutateRouterState from "../../../../../../utils/use-mutate-router-state"
import {
  PALETTE,
  apxTheme,
} from "../../../../../ApplicationFrame/styling/CustomCSS"
import BootstrapTooltip from "../../../../../ReusableComponents/BootstrapTooltip"
import CustomMaterialUIDialog from "../../../../../ReusableComponents/CustomMaterialUIDialog"
import DialogMain from "../../../../../ReusableComponents/Dialog"
import Loading from "../../../../../ReusableComponents/Loading"
import RowDiv from "../../../../../ReusableComponents/RowDiv"
import { deleteCampaignAPI, getConfigType } from "../../campaignsholder/actions"
import useCampaign from "../../campaignsholder/components/use-campaign"
import {
  demoSlackMessager,
  getTagsAPI,
  saveCampaignAPI,
  sendTestConfigToSDK,
  updateCampaignAPI,
} from "../actions"
import {
  MESSAGE_CONFIGURATIONS,
  SET_CONFIG,
  SET_EXPERIMENT,
  SET_META_NAME,
  SET_META_PLATFORM,
  SET_META_TAGS,
} from "../constants"
import { useTracked } from "../store"
import { checkIfValidToPublishOrTest, getRouteForActionType } from "../utils"
import CreateEditCampaignDialog from "./CreateEditCampaignDialog"
import CreateEditVariantDialog from "./CreateEditVariantDialog"
import LaunchTestingDialogContent from "./LaunchTestingDialogContent"

const CREATE_EDIT_CAMPAIGN_DIALOG = <CreateEditCampaignDialog />

let configOuter = null

// We are maintaining this variable globally because
// when we store it as a state variable, the auto save
// handler is unable to get the latest value and the
// purpose of this variable is not solving.
// Though it's not a good practice, we had to maintain it
// like this.

// Find a proper way to address the above stated issue
let blocked = false

const useStyles = makeStyles((theme) => ({
  tabsIndicator: {
    height: 3,
    borderRadius: 0,
  },
  tabRoot: {
    textTransform: "initial",
    minWidth: 72,
    fontSize: 15,
    fontWeight: 600,
    lineHeight: "20px",
    letterSpacing: "0.01em",
    marginRight: theme.spacing(4),
    "&:focus": {
      // color: '#40a9ff',
    },
  },
  disableHover: {
    textTransform: "none",
    cursor: "default",
    backgroundColor: theme.palette.text.disabled,
    color: theme.palette.secondary.main,
    "&:hover": {
      cursor: "default",
      backgroundColor: theme.palette.text.disabled,
      color: theme.palette.secondary.main,
    },
  },
  testButton: {
    textTransform: "none",
    backgroundColor: theme.palette.text.primary,
    color: theme.palette.secondary.main,
    "&:hover": {
      backgroundColor: theme.palette.text.primary,
      color: theme.palette.secondary.main,
    },
  },
  publishButton: {
    textTransform: "none",
    backgroundColor: theme.palette.primary.main,
    color: `${theme.palette.secondary.main} !important`,
  },
}))

const getId = (label) => {
  switch (label) {
    case MESSAGE_CONFIGURATIONS.DESIGN:
      return "tab-design"
    case MESSAGE_CONFIGURATIONS.TARGET:
      return "tab-contextualize"
    case MESSAGE_CONFIGURATIONS.SCHEDULE:
      return "tab-schedule"
    case MESSAGE_CONFIGURATIONS.REVIEW:
      return "tab-review"
    default:
      return "tab"
  }
}

export default function HeaderToolbar({
  app,
  activeStep,
  actionType,
  platform,
  showEditDialog,
  setShowEditDialog,
  stateRef,
  stepperConfig,
  setActiveStep,
  updateConfigOuter,
}) {
  const auth = useAuth()
  const classes = useStyles()
  const { push, location } = useHistory()
  const { url } = useRouteMatch()

  const [state, dispatch] = useTracked()

  const [openCreateOrEditDialog, setOpenCreateOrEditDialog] = useState(
    !url.includes("/edit"),
  )
  const [loading, setLoading] = useState(false)
  const [tagNames, setTagNames] = useState([])
  const [
    createOrEditDialogButtonName,
    setCreateOrEditDialogButtonName,
  ] = useState(url.includes("/edit") ? "Update" : "Create")
  const [publishPending, setPublishPending] = useState(false)
  const [saveInProgress, setSaveInProgress] = useState(false)
  const [showLaunchTestingDialog, setShowLaunchTestingDialog] = useState(false)
  const [showVariantDialog, setShowVariantDialog] = useState(false)
  const [showPublishConfirmDialog, setShowPublishConfirmDialog] = useState(
    false,
  )
  const [showUnsavedChangesDialog, setShowUnsavedChangesDialog] = useState(
    false,
  )

  const { path } = useRouteMatch()

  const { clearStateFields } = useMutateRouterState()

  // Used to differentiate whether the campaign is saved or not
  const [saved, setSaved] = useState(false)

  // When we block user to navigate away, we store the location
  // that he wants to go and when he performs save or cancel
  // we redirect him to the location stored in this variable
  const [lastLocation, setLastLocation] = useState(null)

  const validationRef = useRef("")
  const [validationMessage, setValidationMessage] = useState("")

  useEffect(() => {
    // On Mount we must disable the buttons if it's not eligible for
    // Test or Publish
    checkIfValidToPublishOrTest(stateRef, validationRef, setValidationMessage)

    // Auto Validator
    const autoValidatorHandler = setInterval(() => {
      checkIfValidToPublishOrTest(stateRef, validationRef, setValidationMessage)
    }, 1000)

    // Auto Save Handler
    const handler = setInterval(() => {
      if (
        stateRef.current?.meta?.name !== "" &&
        (!configOuter ||
          !deepEqual(configOuter, stateRef.current, {
            strict: true,
          }))
      ) {
        configOuter = stateRef.current
        saveOrUpdate()
      }
    }, 10000)

    // Unload handler which prevents user to close/reload the tab
    // iff there are some unsaved changes
    const unloadHandler = (event) => {
      if (
        !saved &&
        stateRef.current?.meta?.name !== "" &&
        (!configOuter ||
          !deepEqual(configOuter, stateRef.current, {
            strict: true,
          }))
      ) {
        event.preventDefault()
        return (event.returnValue = "Unsaved changes")
      }
      return event
    }

    window.addEventListener("beforeunload", unloadHandler)
    window.addEventListener("onbeforeunload", unloadHandler)

    return () => {
      clearInterval(handler)
      clearInterval(autoValidatorHandler)

      // De-register the unload handler
      window.removeEventListener("beforeunload", unloadHandler)
      window.addEventListener("onbeforeunload", unloadHandler)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // A flag to control the dialogOpen behaviour from SummaryPage
  useEffect(() => {
    if (showEditDialog !== openCreateOrEditDialog) {
      setOpenCreateOrEditDialog(showEditDialog)
    }
    if (showEditDialog) {
      Apxor.logEvent(
        "CampaignMetaDialogLaunched",
        {
          experience_type: stateRef.current?.meta?.experience_type ?? "",
        },
        APXOR_SDK_CATEGORY_CONSTANTS.CAMPAIGNS,
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showEditDialog])

  useEffect(() => {
    configOuter = stateRef.current
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateConfigOuter])

  const handleVariantDialogDone = (variantName) => {
    let additionalPayload = {}
    //When there are already ui's added and then we add a variant, indexing existing ui's as variant A
    if (!!stateRef.current.uis.length) {
      // additionalPayload["payload"] = Array.from(
      //   { length: stateRef.current.uis.length },
      //   (duckTypeDummy, index) => index
      // );
      additionalPayload["payload"] = stateRef.current.uis.map(
        (eachUi) => eachUi.index,
      )
    }

    dispatch({
      type: SET_EXPERIMENT,
      name: variantName,
      ...additionalPayload,
    })
    setShowVariantDialog(false)
  }
  useEffect(() => {
    if (openCreateOrEditDialog) {
      setLoading(true)
      getTagsAPI(auth, auth.appId, "walkthrough").then((res) => {
        setTagNames(res)
      })
      setLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openCreateOrEditDialog])

  const handleDone = (name, platform, tags) => {
    dispatch({
      type: SET_META_NAME,
      name: name,
    })
    dispatch({
      type: SET_META_PLATFORM,
      platform,
    })
    dispatch({
      type: SET_META_TAGS,
      tags: tags || [],
    })

    // If the campaign is created, we need to clear the state of the browser
    clearStateFields(["isDuplicated"])

    setOpenCreateOrEditDialog(false)
    setShowEditDialog(false)

    if (createOrEditDialogButtonName === "Create") {
      setTimeout(() => {
        saveOrUpdate()
      }, 1000)
    }
  }

  const handleCancel = async () => {
    if (createOrEditDialogButtonName === "Update") {
      if (location?.state?.isDuplicated) {
        // Perform a cleanup, delete the duplicated campaign
        await deleteCampaignAPI(auth, auth?.appId, stateRef?.current?._id, {
          configType: getConfigType(stateRef?.current),
        }).then((res) => {
          if (res) {
            // Clear the state of the browser
            clearStateFields(["isDuplicated"])
            push(`/apps/${auth?.appId}/${getRouteForActionType(actionType)}`)
          }
        })
      }
      setOpenCreateOrEditDialog(false)
      setShowEditDialog(false)
    }
    if (createOrEditDialogButtonName === "Create") {
      push(`/apps/${auth.appId}/${getRouteForActionType(actionType)}`)
    }
  }

  const saveOrUpdate = (
    successCallback = () => {
      setPublishPending(false)
      setSaveInProgress(false)
    },
    errorCallback = () => {
      setPublishPending(false)
      setSaveInProgress(false)
    },
    isBeforePublish = false,
  ) => {
    // We shouldn't allow auto save
    // when we display the unsaved changes dialog or
    // publish happening or
    // already a save is in progress
    if (publishPending || saveInProgress || blocked) {
      return
    }
    setSaved(false)
    const config = stateRef.current
    const functionToCall = config.hasOwnProperty("_id")
      ? updateCampaignAPI
      : saveCampaignAPI
    if (!config.hasOwnProperty("_id") || !config.track) {
      config.track = {
        created_at: moment().toISOString(),
      }
      config.meta.created_by = auth?.user?.email
    }
    if (config.track.created_at) {
      config.track.updated_at = moment().toISOString()
      config.meta.updated_by = auth?.user?.email
    }
    const updatedConfig = {
      ...config,
      track: config.track,
    }

    if (isBeforePublish) {
      updatedConfig.enabled = true
    }

    setPublishPending(true)
    functionToCall(
      auth,
      auth.appId,
      { configType: updatedConfig.meta.config_type },
      updatedConfig,
      updatedConfig._id,
    )
      .then((response) => {
        setSaved(true)
        if (!config.hasOwnProperty("_id")) {
          if (!stateRef.current?.meta?.experience_type) {
            demoSlackMessager(
              auth.user,
              "Self Created",
              stateRef.current?.meta?.name || "NA",
              actionType === "walkthrough" ? "Campaign" : "Survey",
            )
          }

          Apxor.logEvent(
            "CampaignCreated",
            {
              name: stateRef.current?.meta?.name || "NA",
              actionType: actionType,
              experience_type: stateRef.current?.meta?.experience_type ?? "",
            },
            APXOR_SDK_CATEGORY_CONSTANTS.CAMPAIGNS,
          )

          window.history.replaceState(
            null,
            `${stateRef.current?.meta?.name} | Apxor Dashboard`,
            `/apps/${auth.appId}/${
              path.indexOf("surveys") > -1 ? "surveys" : "walkthroughs"
            }/${response.id}/edit`,
          )

          dispatch({
            type: SET_CONFIG,
            config: {
              ...config,
              _id: response.id,
            },
          })
        }
        if (!stateRef.current._id) {
          stateRef.current._id = response.id
        }
        successCallback(stateRef.current)
      })
      .catch((err) => {
        errorCallback()
      })
  }

  const { publishCampaign } = useCampaign(
    stateRef?.current,
    () => {},
    actionType,
    auth?.appId,
  )

  return (
    <>
      <RowDiv
        center
        styles={{
          justifyContent: "space-between",
          alignItems: "center",
          marginBlock: "1rem",
        }}
      >
        <RowDiv center styles={{ alignItems: "baseline" }}>
          <RowDiv center>
            <IconButton
              style={{ padding: 0 }}
              onClick={() => {
                push(
                  `/apps/${auth.appId}/${getRouteForActionType(actionType)}`,
                  {
                    isExperiment: stateRef?.current?.meta?.isExperiment,
                  },
                )
              }}
            >
              <ChevronLeft style={{ color: "#002845" }} />
            </IconButton>
            <BootstrapTooltip
              placement="top"
              title={state?.config?.meta?.name || "New Campaign"}
            >
              <Typography
                variant="h4"
                style={{
                  fontWeight: 500,
                  fontSize: 24,
                  maxWidth: "250px",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {state?.config?.meta?.name || "New Campaign"}
              </Typography>
            </BootstrapTooltip>
            <IconButton
              disableRipple
              style={{
                marginTop: 2,
                color: "#002845",
              }}
              onClick={() => {
                setOpenCreateOrEditDialog(true)
                setCreateOrEditDialogButtonName("Update")
              }}
            >
              <CreateOutlined
                style={{
                  fontSize: "20px",
                }}
              />
            </IconButton>
          </RowDiv>
        </RowDiv>
        <RowDiv center>
          <Breadcrumbs
            style={{
              flex: 1,
            }}
            separator={
              <ChevronRight
                style={{
                  color: "#7D919F",
                  fontSize: "22px",
                }}
              />
            }
          >
            {Object.values(stepperConfig).map((label) => {
              const index = Object.values(stepperConfig).indexOf(label)
              return (
                <Button
                  key={getId(label)}
                  id={getId(label)}
                  style={{
                    fontSize: "17px",
                    paddingInline: apxTheme.spacing(2),
                    fontWeight: "600",
                    color:
                      activeStep >= index
                        ? apxTheme.palette.primary.main
                        : "#7D919F",
                    textDecoration: activeStep === index ? "underline" : "none",
                    textDecorationColor:
                      activeStep >= index
                        ? apxTheme.palette.primary.main
                        : "#7D919F",
                    textUnderlineOffset: "8px",
                  }}
                  variant="text"
                  color="primary"
                  size="large"
                  onClick={(e) => {
                    let eventName = "NA"
                    switch (index) {
                      case 0:
                        eventName = "DesignClicked"
                        break
                      case 1:
                        eventName = "ContextualizeClicked"
                        break
                      case 2:
                        eventName = "ScheduleClicked"
                        break
                      case 3:
                        eventName = "ReviewClicked"
                        break
                      default:
                        eventName = "HeaderTabsClicked"
                        break
                    }
                    Apxor.logEvent(
                      eventName,
                      {
                        name: stateRef.current?.meta?.name || "NA",
                        actionType: actionType,
                        experience_type:
                          stateRef.current?.meta?.experience_type ?? "",
                      },
                      APXOR_SDK_CATEGORY_CONSTANTS.CAMPAIGNS,
                    )
                    setActiveStep(index)
                  }}
                >
                  {label}
                </Button>
              )
            })}
          </Breadcrumbs>
        </RowDiv>
        <RowDiv center applyMargin>
          {isAccessable(app, FEATURE_KEY_ENUM.AB_ANALYSIS) &&
            actionType === ACTIONS_ENUM.WALKTHROUGH &&
            !stateRef?.current?.meta?.isExperiment && (
              <BootstrapTooltip
                placement="bottom"
                title={
                  "Convert this campaign to an experiment                              "
                }
              >
                <Button
                  disabled={!!isExperienceDashboard()}
                  style={{
                    maxWidth: 34,
                    background: "#FFFFFF",
                    border: "1px solid #CCD4DA",
                    borderRadius: "3px",
                  }}
                  variant="contained"
                  disableRipple
                  disableTouchRipple
                  onClick={() => setShowVariantDialog(true)}
                >
                  A/B
                </Button>
              </BootstrapTooltip>
            )}
          {validationMessage !== "" && !!!isExperienceDashboard() && (
            <BootstrapTooltip placement="bottom" title={validationMessage}>
              <Button
                variant="contained"
                disableRipple
                disableTouchRipple
                className={classes.disableHover}
              >
                Test
              </Button>
            </BootstrapTooltip>
          )}
          {validationMessage === "" && !!!isExperienceDashboard() && (
            <Button
              variant="contained"
              color="primary"
              className={classes.testButton}
              disabled={validationMessage !== ""}
              onClick={() => {
                if (
                  !checkIfValidToPublishOrTest(
                    stateRef,
                    validationRef,
                    setValidationMessage,
                  )
                ) {
                  // TODO: Show a Snackbar here
                  return
                }
                // If throttling is enabled, send the config using SSE
                // Otherwise, save the config and redirect
                setShowLaunchTestingDialog(true)
              }}
            >
              Test
            </Button>
          )}
          {console.log(validationMessage)}
          <Button
            id="publish-campaign-button"
            variant="contained"
            color="primary"
            style={{
              backgroundColor:
                validationMessage !== ""
                  ? PALETTE.primary.hint
                  : PALETTE.primary.main,
            }}
            className={classes.publishButton}
            disabled={validationMessage !== ""}
            onClick={() => {
              if (
                !checkIfValidToPublishOrTest(
                  stateRef,
                  validationRef,
                  setValidationMessage,
                )
              ) {
                // TODO: Show a Snackbar here
                return
              }
              setShowPublishConfirmDialog(true)
            }}
          >
            Publish
          </Button>
        </RowDiv>
      </RowDiv>
      {openCreateOrEditDialog && (
        <DialogMain
          status={openCreateOrEditDialog}
          handleClose={() => {
            setOpenCreateOrEditDialog(false)
            setShowEditDialog(false)
          }}
          confirmLabel=""
          dialogProps={{
            disableBackdropClick: true,
            disableEscapeKeyDown: true,
            scroll: "body",
            maxWidth: "md",
          }}
          noActions={true}
          children={React.cloneElement(CREATE_EDIT_CAMPAIGN_DIALOG, {
            handleCancel,
            metaPlatform: stateRef.current?.meta?.platform, // config.meta.platform
            appPlatform: platform, // app.basic_info.platform,
            handleDone,
            task: createOrEditDialogButtonName,
            tagNames: tagNames,
            loading: loading,
            title: actionType === "surveys" ? "Survey" : "Campaign",
          })}
        />
      )}
      <CustomMaterialUIDialog
        dialogProps={{
          disableBackdropClick: true,
          disableEscapeKeyDown: true,
        }}
        dialogContent={
          <LaunchTestingDialogContent
            handleDone={() => {
              saveOrUpdate(
                () => {
                  setShowLaunchTestingDialog(false)

                  const queryParams = {
                    isExperiment: false,
                  }
                  const {
                    meta: { isExperiment } = {
                      isExperiment: false,
                    },
                    testing: { selectedVariant = "A" },
                  } = stateRef.current
                  if (isExperiment && selectedVariant !== -1) {
                    queryParams.isExperiment = true
                    queryParams.variant = selectedVariant
                  }
                  sendTestConfigToSDK(
                    auth,
                    auth.appId,
                    queryParams,
                    stateRef.current,
                  )

                  push(
                    `/apps/${auth.appId}/${getRouteForActionType(actionType)}`,
                    {
                      isExperiment: isExperiment,
                    },
                  )
                  Apxor.logEvent(
                    "TestCampaign",
                    {
                      name: stateRef.current?.meta?.name || "NA",
                      actionType: actionType,
                      experience_type:
                        stateRef.current?.meta?.experience_type ?? "",
                    },
                    APXOR_SDK_CATEGORY_CONSTANTS.CAMPAIGNS,
                  )
                },
                () => {
                  // TODO: Show snackbar saying saving is failed
                },
              )
            }}
          />
        }
        dialogContentStyles={{
          overflowX: "hidden",
          padding: "8px 8px",
        }}
        maxWidth={"md"}
        openFlag={showLaunchTestingDialog}
        onClose={() => setShowLaunchTestingDialog(false)}
        onDialogClose={() => setShowLaunchTestingDialog(false)}
      />
      <CustomMaterialUIDialog
        dialogProps={{
          disableBackdropClick: true,
          disableEscapeKeyDown: true,
        }}
        title={
          <Typography
            style={{
              fontSize: "26px",
              fontWeight: "700",
              marginLeft: "25px",
            }}
          >
            Confirm Publish
          </Typography>
        }
        noTitleBg={true}
        dialogContent={
          <Box
            style={{
              padding: "20px",
              paddingTop: "10px",
            }}
          >
            <Typography color="textPrimary" variant="h5">
              Are you sure you want to publish the campaign{" "}
              <b>{stateRef.current?.meta?.name}</b>?
              <br />
              {isExperienceDashboard() && (
                <>
                  All active campaigns and surveys will be{" "}
                  <b
                    style={{
                      textTransform: "uppercase",
                    }}
                  >
                    paused
                  </b>
                  .
                </>
              )}
            </Typography>
          </Box>
        }
        dialogActions={
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              paddingInline: "30px",
              paddingBottom: "20px",
            }}
          >
            <Button
              color="default"
              size="large"
              style={{
                fontSize: "16px",
                fontWeight: "700",
                marginRight: "20px",
              }}
              onClick={() => setShowPublishConfirmDialog(false)}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              variant="contained"
              size="large"
              style={{
                fontSize: "16px",
                fontWeight: "700",
              }}
              onClick={() => {
                setPublishPending(true)
                saveOrUpdate(
                  publishCampaign,
                  () => {
                    // TODO: Show Snackbar that save or update failed
                    setPublishPending(false)
                  },
                  true,
                )
              }}
            >
              {publishPending ? <Loading size={14} /> : "Publish"}
            </Button>
          </Box>
        }
        hideCloseIcon={true}
        maxWidth="sm"
        openFlag={showPublishConfirmDialog}
        onClose={() => setShowPublishConfirmDialog(false)}
        onDialogClose={() => setShowPublishConfirmDialog(false)}
      />
      <CustomMaterialUIDialog
        isFullWidth={false}
        dialogProps={{
          disableBackdropClick: true,
          disableEscapeKeyDown: true,
        }}
        dialogContent={
          <CreateEditVariantDialog handleDone={handleVariantDialogDone} />
        }
        dialogContentStyles={{
          overflowX: "hidden",
          padding: "8px 8px",
        }}
        maxWidth={"md"}
        openFlag={showVariantDialog}
        onClose={() => setShowVariantDialog(false)}
        onDialogClose={() => setShowVariantDialog(false)}
      />
      {/* A custom dialog will be when there are some unsaved changes and
          users try to navigate away from this page
        */}
      <Prompt
        when={
          !saved &&
          stateRef.current?.meta?.name !== "" &&
          (!configOuter ||
            !deepEqual(configOuter, stateRef.current, {
              strict: true,
            }))
        }
        message={(nextLocation) => {
          if (
            !saved ||
            (stateRef.current?.meta?.name !== "" &&
              (!configOuter ||
                !deepEqual(configOuter, stateRef.current, {
                  strict: true,
                })))
          ) {
            setLastLocation(nextLocation)
            blocked = true
            setShowUnsavedChangesDialog(true)
            return false
          }
          return true
        }}
      />
      <CustomMaterialUIDialog
        dialogProps={{
          disableBackdropClick: true,
          disableEscapeKeyDown: true,
        }}
        dialogActions={
          <div
            style={{
              display: "flex",
              gap: 15,
              marginTop: 16,
              paddingRight: 20,
              paddingBottom: 16,
            }}
          >
            <Button
              color="text"
              style={{ fontSize: 16, fontWeight: 700 }}
              onClick={() => {
                setShowUnsavedChangesDialog(false)
                setSaved(true)

                // This small delay will ensure that the custom dialog
                // never appears back when we try to do `history.push`
                setTimeout(() => {
                  if (lastLocation && lastLocation.pathname !== "")
                    push(lastLocation.pathname)
                }, 100)
              }}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              variant={saveInProgress ? "text" : "contained"}
              disabled={saveInProgress}
              onClick={() => {
                blocked = false
                saveOrUpdate(() => {
                  // Once the save is successful, we push to the location
                  // that the user earlier wants to go
                  setSaveInProgress(false)
                  if (lastLocation && lastLocation.pathname) {
                    push(lastLocation.pathname)
                  }

                  // FIXME: What if the above if condition fails? Will we ever reach here?
                })

                // This will display a loading indicator when save is happening
                setSaveInProgress(true)
              }}
            >
              {saveInProgress ? <Loading size={14} /> : "Save"}
            </Button>
          </div>
        }
        dialogContent={
          <>
            <RowDiv styles={{ justifyContent: "space-between" }}>
              <Typography
                style={{
                  fontSize: 28,
                  fontWeight: 700,
                  lineHeight: "38px",
                }}
              >
                Save changes
              </Typography>
              <IconButton
                aria-label="close"
                style={{ color: "#A1ADB6", padding: 0 }}
                onClick={() => {
                  blocked = false
                  setShowUnsavedChangesDialog(false)
                }}
              >
                <CloseIcon />
              </IconButton>
            </RowDiv>
            <Typography
              style={{
                fontSize: 14,
                fontWeight: 500,
                marginTop: 17,
                lineHeight: "19px",
              }}
            >
              You have some unsaved changes to this campaign, do you want to
              save them?
            </Typography>
          </>
        }
        hideCloseIcon={true}
        maxWidth="sm"
        openFlag={showUnsavedChangesDialog}
        onClose={() => {
          blocked = false
          setShowUnsavedChangesDialog(false)
        }}
        onDialogClose={() => {
          blocked = false
          setShowUnsavedChangesDialog(false)
        }}
      />
    </>
  )
}

