import { callApi } from "../../../../api"
import { makeGetRequest } from "../../../common/actions"

const DASHBOARD_STATS = "art-configs/metrics"
const DASHBOARD_CAMPAIGNS = "art-configs/last"
const USER_COUNT = "active-users"
const DEMO_SLACK_WEBHOOK = "demo/slack/message"

export function getCampaignCountAPI(
  auth,
  appId,
  queryParams,
  enableCancellation = true,
) {
  const onSuccessCallback = (json) => {
    return json
  }
  const onFailureCallback = (response) => {
    return []
  }
  return makeGetRequest(
    DASHBOARD_STATS,
    auth,
    appId,
    { configType: queryParams },
    onSuccessCallback,
    onFailureCallback,
    enableCancellation,
  )
}
//configType can be walkthroughs or surveys
export function getCampaignsAPI(
  auth,
  appId,
  configType,
  enableCancellation = true,
) {
  const onSuccessCallback = (json) => {
    return json
  }
  const onFailureCallback = (response) => {
    return []
  }
  const queryParams = { configType: configType, limit: 3 }
  return makeGetRequest(
    DASHBOARD_CAMPAIGNS,
    auth,
    appId,
    queryParams,
    onSuccessCallback,
    onFailureCallback,
    enableCancellation,
  )
}

export function getUserCountAPI(auth, appId) {
  const onSuccessCallback = (json) => {
    return json
  }
  const onFailureCallback = (response) => {
    return []
  }
  return makeGetRequest(
    USER_COUNT,
    auth,
    appId,
    {},
    onSuccessCallback,
    onFailureCallback,
  )
}

export function getCampaignsReachedAPI(auth, appId, configType) {
  const onSuccessCallback = (json) => {
    return json
  }
  const onFailureCallback = (response) => {
    return []
  }
  const queryParams = { configType: configType, limit: 3 }
  return makeGetRequest(
    DASHBOARD_CAMPAIGNS,
    auth,
    appId,
    queryParams,
    onSuccessCallback,
    onFailureCallback,
  )
}

export function getCampaignExposedAPI(
  auth,
  appId,
  queryParams,
  apiName = "d0_uninstall",
) {
  const onSuccessCallback = (json) => {
    return json
  }
  const onFailureCallback = (response) => {
    return []
  }
  return makeGetRequest(
    apiName,
    auth,
    appId,
    { configType: queryParams },
    onSuccessCallback,
    onFailureCallback,
  )
}

/* -----------------------------------------------------------------------------------
Created the slack messager function for the Ingrate My APP button onclick
-----------------------------------------------------------------------------------
*/

export function demoSlackMessagerIntegrate(auth) {
  let message = {
    text: "Sand-Box Slack Notifications",
    blocks: [
      {
        type: "section",
        text: {
          type: "mrkdwn",
          text: "*Activity Performed: Clicked on Integrate App !!*",
        },
      },
      {
        type: "section",
        text: {
          type: "mrkdwn",
          text: `Name: ${auth.name}`,
        },
      },
      {
        type: "section",
        text: {
          type: "mrkdwn",
          text: `Phone: ${auth.phone}`,
        },
      },
      {
        type: "section",
        text: {
          type: "mrkdwn",
          text: `Email: ${auth.email}`,
        },
      },
      {
        type: "section",
        text: {
          type: "mrkdwn",
          text: `Role in Organisation: ${auth.job_title}`,
        },
      },
    ],
  }

  const config = {
    method: "POST",
    body: JSON.stringify(message),
  }

  const onSuccessCallback = (json) => {
    if (json.status === "success") {
    } else {
    }
  }

  const onFailureCallback = (response) => {
    return response
  }

  return callApi(
    DEMO_SLACK_WEBHOOK,
    config,
    onSuccessCallback,
    onFailureCallback,
  )
}
