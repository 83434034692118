import {
  makeGetRequest,
  makePostRequest,
  makePutRequest,
} from "../../../../common/actions"

import { callApi } from "../../../../../api"

const ART_CONFIGS = "art-configs"
const ART_CONFIG = "art-config"
const TAGS = "tags"

const DEMO_SLACK_WEBHOOK = "demo/slack/message"

export function saveCampaignAPI(auth, appId, queryParams, postBody) {
  const onSuccessCallback = (response) => {
    return response
  }

  const onFailureCallback = (response) => {
    return {}
  }
  return makePostRequest(
    ART_CONFIGS,
    auth,
    appId,
    queryParams,
    postBody,
    onSuccessCallback,
    onFailureCallback,
  )
}

export function updateCampaignAPI(
  auth,
  appId,
  queryParams,
  postBody,
  campaignId,
) {
  const onSuccessCallback = (response) => {
    return response
  }

  const onFailureCallback = (response) => {
    return {}
  }
  return makePutRequest(
    `${ART_CONFIGS}/${campaignId}`,
    auth,
    appId,
    queryParams,
    postBody,
    onSuccessCallback,
    onFailureCallback,
  )
}

export function sendTestConfigToSDK(auth, appId, queryParams = {}, postBody) {
  const onSuccessCallback = (response) => {
    return response
  }

  const onFailureCallback = (response) => {
    return {}
  }

  return makePostRequest(
    ART_CONFIG,
    auth,
    appId,
    queryParams,
    postBody,
    onSuccessCallback,
    onFailureCallback,
    window.SSE_ROOT,
  )
}

export function getTagsAPI(auth, appId, configType) {
  const onSuccessCallback = (json) => {
    return Array.isArray(json) ? json : []
  }
  const onFailureCallback = (response) => {
    return []
  }
  return makeGetRequest(
    TAGS,
    auth,
    appId,
    { configType },
    onSuccessCallback,
    onFailureCallback,
  )
}

/* -----------------------------------------------------------------------------------
Created the slack messager function and the dummy object for the submitted details for the contact us form
-----------------------------------------------------------------------------------
*/

export function demoSlackMessager(
  user,
  activity_performed,
  campaign_name,
  action_type,
) {
  let message
  if (activity_performed === "Guided Published") {
    message = {
      text: "Sand-Box Slack Notifications",
      blocks: [
        {
          type: "section",
          text: {
            type: "mrkdwn",
            text: `*Activity Performed: Guided ${action_type} Published !!*`,
          },
        },
        {
          type: "section",
          text: {
            type: "mrkdwn",
            text: `Name: ${user.name}`,
          },
        },
        {
          type: "section",
          text: {
            type: "mrkdwn",
            text: `Phone: ${user.phone}`,
          },
        },
        {
          type: "section",
          text: {
            type: "mrkdwn",
            text: `Email: ${user.email}`,
          },
        },
        {
          type: "section",
          text: {
            type: "mrkdwn",
            text: `Role in Organisation: ${user.job_title}`,
          },
        },
        {
          type: "section",
          text: {
            type: "mrkdwn",
            text: `${action_type} Name: ${campaign_name}`,
          },
        },
      ],
    }
  } else if (activity_performed === "Self Created") {
    message = {
      text: "Sand-Box Slack Notifications",
      blocks: [
        {
          type: "section",
          text: {
            type: "mrkdwn",
            text: `*Activity Performed: Started Creating Self ${action_type} !!*`,
          },
        },
        {
          type: "section",
          text: {
            type: "mrkdwn",
            text: `Name: ${user.name}`,
          },
        },
        {
          type: "section",
          text: {
            type: "mrkdwn",
            text: `Phone: ${user.phone}`,
          },
        },
        {
          type: "section",
          text: {
            type: "mrkdwn",
            text: `Email: ${user.email}`,
          },
        },
        {
          type: "section",
          text: {
            type: "mrkdwn",
            text: `Role in Organisation: ${user.job_title}`,
          },
        },
        {
          type: "section",
          text: {
            type: "mrkdwn",
            text: `${action_type} Name: ${campaign_name}`,
          },
        },
      ],
    }
  } else if (activity_performed === "Self Published") {
    message = {
      text: "Sand-Box Slack Notifications",
      blocks: [
        {
          type: "section",
          text: {
            type: "mrkdwn",
            text: `*Activity Performed: Published self created ${action_type} !!*`,
          },
        },
        {
          type: "section",
          text: {
            type: "mrkdwn",
            text: `Name: ${user.name}`,
          },
        },
        {
          type: "section",
          text: {
            type: "mrkdwn",
            text: `Phone: ${user.phone}`,
          },
        },
        {
          type: "section",
          text: {
            type: "mrkdwn",
            text: `Email: ${user.email}`,
          },
        },
        {
          type: "section",
          text: {
            type: "mrkdwn",
            text: `Role in Organisation: ${user.job_title}`,
          },
        },
        {
          type: "section",
          text: {
            type: "mrkdwn",
            text: `${action_type} Name: ${campaign_name}`,
          },
        },
      ],
    }
  } else {
    return
  }

  const config = {
    method: "POST",
    body: JSON.stringify(message),
  }

  const onSuccessCallback = (response) => {
    return response
  }

  const onFailureCallback = (response) => {
    return response
  }

  return callApi(
    `${DEMO_SLACK_WEBHOOK}`,
    config,
    onSuccessCallback,
    onFailureCallback,
  )
}

