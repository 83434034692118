import { callApi } from "../../../api"
import { makePostRequest } from "../../common/actions"

const CONTACT_US_API = "contact-us"
const DEMO_SLACK_WEBHOOK = "/demo/slack/message"

export function demoSlackContactMessager(auth, detailSubmitted) {
  let message = {
    text: "Details submitted for scheduling a demo !!",
    blocks: [
      {
        type: "section",
        text: {
          type: "mrkdwn",
          text:
            "*Activity Performed: Details submitted for scheduling a demo !!*",
        },
      },
      {
        type: "section",
        text: {
          type: "mrkdwn",
          text: `Name: ${auth.name}`,
        },
      },
      {
        type: "section",
        text: {
          type: "mrkdwn",
          text: `Phone: ${auth.phone}`,
        },
      },
      {
        type: "section",
        text: {
          type: "mrkdwn",
          text: `Email: ${auth.email}`,
        },
      },
      {
        type: "section",
        text: {
          type: "mrkdwn",
          text: `Role in Organisation: ${auth.job_title}`,
        },
      },
      {
        type: "section",
        text: {
          type: "mrkdwn",
          text: "Details Submitted !!",
        },
      },
      {
        type: "section",
        text: {
          type: "mrkdwn",
          text: `Entered Name : ${detailSubmitted.name}`,
        },
      },
      {
        type: "section",
        text: {
          type: "mrkdwn",
          text: `Entered Phone : ${detailSubmitted.phone}`,
        },
      },
      {
        type: "section",
        text: {
          type: "mrkdwn",
          text: `Entered Email : ${detailSubmitted.email}`,
        },
      },
      {
        type: "section",
        text: {
          type: "mrkdwn",
          text: `Entered Message : ${detailSubmitted.message}`,
        },
      },
    ],
  }

  const config = {
    method: "POST",
    body: JSON.stringify(message),
  }

  const onSuccessCallback = (json) => {
    if (json.status === "success") {
      //console.log("Message Sent !!")
    } else {
      //console.log("Message Not Sent !!")
    }
  }

  const onFailureCallback = (response) => {
    return response
  }

  return callApi(
    DEMO_SLACK_WEBHOOK,
    config,
    onSuccessCallback,
    onFailureCallback,
  )
}

export async function sendContactUsMessage(auth, postBody, queryParams) {
  const onSuccessCallback = (json) => {
    return json
  }
  const onFailureCallback = (response) => {
    return {}
  }
  return makePostRequest(
    CONTACT_US_API,
    auth,
    auth.appId,
    queryParams,
    postBody,
    onSuccessCallback,
    onFailureCallback,
    "v1/external/",
  )
}
