import Apxor from "apxor"
import axios from "axios"
import moment from "moment"
import { useRef, useState } from "react"
import { useHistory } from "react-router-dom"
import {
  DEMO_ECOMMERCE_APP_ID,
  isExperienceDashboard,
} from "../../../../../../config"
import { APXOR_SDK_CATEGORY_CONSTANTS } from "../../../../../../constants/Endpoints"
import { useAuth } from "../../../../../../contexts/AuthContext"
import {
  getCampaignAPI,
  getCampaignsAPI,
  getSignedURLToGetImage,
  getSignedURLToPost,
  uploadImageUsingSignedURL,
} from "../../../actions"
import { CAMPAIGNS_HOLDER_TABS, base64ToBlob } from "../../../utils"
import {
  demoSlackMessager,
  saveCampaignAPI,
  updateCampaignAPI,
} from "../../campaigncreator/actions"
import { SCHEDULING_ENUM } from "../../campaigncreator/constants"
import { getRouteForActionType } from "../../campaigncreator/utils"
import {
  deleteCampaignAPI,
  getConfigType,
  publishCampaignAPI,
  toggleCampaignAPI,
} from "../actions"
import {
  DEMO_TEMPLATE_CONFIG_TYPES,
  INITIAL_CAMPAIGN_PROGRESS_STATE,
} from "../constants"
import { INITIAL_STATE } from "./CampaignCard"

export default function useCampaign(
  campaignConfig,
  refreshCampaigns,
  actionType,
  appId,
) {
  const auth = useAuth()
  const { push } = useHistory()
  const [state, setState] = useState({
    ...INITIAL_STATE,
    showSnackbar: false,
    snackBarMessage: "",
    showCampaignInfo: false,
  })

  const [completeConfig, setCompleteConfig] = useState({})

  const [progressState, setProgressState] = useState(
    INITIAL_CAMPAIGN_PROGRESS_STATE,
  )

  const [showABManualPubishDialog, setShowABManualPubishDialog] = useState(
    false,
  )
  const [publishPending, setPublishPending] = useState(false)
  const [showPublishConfirmDialog, setShowPublishConfirmDialog] = useState(
    false,
  )
  const validationRef = useRef("")
  const [validationMessage, setValidationMessage] = useState("")

  const [anchorEl, setAnchorEl] = useState(null)
  const [pending, setPending] = useState(false)
  const open = Boolean(anchorEl)

  async function fetchConfig(toggleDialog = true, successCallback = null) {
    const response = await getCampaignAPI(auth, appId, campaignConfig._id, {
      configType: campaignConfig.meta.config_type,
    })
    setCompleteConfig(response)
    setState({
      ...state,
      showCampaignInfo: toggleDialog ? !state.showCampaignInfo : false,
    })
    if (successCallback) {
      successCallback()
    }
  }

  function handleSnackbarClosed() {
    setProgressState({
      ...progressState,
      statusMessage: null,
    })
  }

  function handleShowCampaignInfo() {
    !state.showCampaignInfo
      ? fetchConfig()
      : setState({
          ...state,
          showCampaignInfo: !state.showCampaignInfo,
        })
  }

  async function toggleCampaign(campaignConfig, enabled) {
    const campaign_failed = enabled
      ? "campaign_resume_failed"
      : "campaign_pause_failed"
    const campaign_pending = enabled
      ? "campaign_resume_pending"
      : "campaign_pause_pending"
    setProgressState({
      ...progressState,
      statusMessage: null,
      [campaign_failed]: false,
      [campaign_pending]: true,
    })
    if (isExperienceDashboard()) {
      const [
        { messages: activeCampaigns },
        { messages: activeSurveys },
      ] = await Promise.all([
        getCampaignsAPI(
          auth,
          DEMO_ECOMMERCE_APP_ID,
          {
            configType: "walkthrough",
            kind: "regular",
            state: "ACTIVE",
            limit: 10000,
          },
          false,
        ),
        getCampaignsAPI(
          auth,
          DEMO_ECOMMERCE_APP_ID,
          {
            configType: "survey",
            kind: "regular",
            state: "ACTIVE",
            limit: 10000,
          },
          false,
        ),
      ])

      const activeCampaignsAndSurveys = [...activeCampaigns, ...activeSurveys]
      const convertToPausedPromises = activeCampaignsAndSurveys.map((tmpl) =>
        toggleCampaignAPI(auth, DEMO_ECOMMERCE_APP_ID, tmpl._id, {
          configType: tmpl?.meta?.config_type,
          enableStatus: false,
        }),
      )
      await Promise.all(convertToPausedPromises)
    }

    await toggleCampaignAPI(auth, appId, campaignConfig._id, {
      configType: getConfigType(campaignConfig),
      enableStatus: enabled.toString(),
    })
    let failed = false
    let pending = false
    if (refreshCampaigns) {
      refreshCampaigns()
    }
    setProgressState({
      ...progressState,
      statusMessage: failed
        ? `Failed to ${enabled ? "resume" : "pause"}`
        : `Campaign ${enabled ? "resumed" : "paused"} successfully`,
      [campaign_pending]: pending,
      [campaign_failed]: failed,
    })
    setAnchorEl(null)
    Apxor.logEvent(
      "EditCampaignClicked",
      {
        name: campaignConfig.meta.name || "NA",
        actionType: actionType,
        experience_type: campaignConfig?.meta?.experience_type ?? "",
      },
      APXOR_SDK_CATEGORY_CONSTANTS.CAMPAIGNS,
    )
  }

  async function deleteCampaign(campaignConfig) {
    try {
      setProgressState({
        ...progressState,
        statusMessage: null,
        campaign_delete_failed: false,
        campaign_delete_pending: true,
      })
      const response = await deleteCampaignAPI(
        auth,
        appId,
        campaignConfig._id,
        {
          configType: getConfigType(campaignConfig),
        },
      )
      let failed
      let pending = false
      if (response) {
        failed = false
        if (refreshCampaigns) {
          refreshCampaigns()
        }
      } else {
        failed = true
      }
      setProgressState({
        ...progressState,
        statusMessage: failed
          ? "Failed to delete. Try again"
          : "Campaign deleted successfully",
        campaign_delete_pending: pending,
        campaign_delete_failed: failed,
      })
    } catch (error) {
      setProgressState({
        ...progressState,
        campaign_delete_failed: true,
        campaign_delete_pending: false,
        statusMessage: "Failed to delete. Try again",
      })
    }
  }

  function handleDuplicate(campaignConfig, isNotATemplate = false) {
    setPending(true)
    getCampaignAPI(auth, appId, campaignConfig._id, {
      configType:
        campaignConfig.meta.config_type === "walkthrough"
          ? isExperienceDashboard() && !isNotATemplate
            ? DEMO_TEMPLATE_CONFIG_TYPES.WALKTHROUGHS
            : "walkthrough"
          : isExperienceDashboard() && !isNotATemplate
          ? DEMO_TEMPLATE_CONFIG_TYPES.SURVEYS
          : "survey",
    }).then((response) => {
      if (isExperienceDashboard())
        setCompleteConfig({
          ...response,
          meta: {
            ...response.meta,
            experience_type: campaignConfig?.meta?.experience_type ?? "",
          },
        })
      else setCompleteConfig(response)

      setPending(false)

      const now = moment().toISOString()
      const { _id, mutation_id, meta, ...others } = {
        ...response,
        meta: {
          ...response.meta,
          experience_type: campaignConfig?.meta?.experience_type ?? "",
        },
      }
      const newTags = meta?.tags?.filter((tag) => tag !== "Try Demo") ?? []
      const newConfig = {
        ...others,
        meta: {
          ...meta,
          name:
            isExperienceDashboard() &&
            meta?.tags?.some((tag) => tag === "Try Demo")
              ? `${meta?.name}-${moment().format("ddd HH:mm")}`
              : `${meta?.name} (Copy)`,
          created_by: auth?.user?.email,
          tags: newTags,
        },
        track: {
          created_at: now,
          updated_at: now,
        },
        published: false,
        enabled: true,
        old_publish_ids: [],
        state: CAMPAIGNS_HOLDER_TABS.DRAFTS,
        validity: {
          start_date: moment().toISOString(),
          end_date: moment().add("days", 1).toISOString(),
        },
        scheduling: {
          schedule_date: moment().format("YYYY-MM-DD"),
          schedule_time: moment().format("HH:mm"),
          schedule: SCHEDULING_ENUM.NOW,
          isRecurEnabled: false,
          rollSegment: false,
          rollInterval: 1,
          recur: "DAY",
          step: 1,
        },
      }

      saveCampaignAPI(
        auth,
        appId,
        { configType: meta.config_type },
        newConfig,
      ).then((response) => {
        if (!meta.experience_type) {
          demoSlackMessager(
            auth.user,
            "Self Created",
            meta?.name,
            meta.config_type === "walkthrough" ? "Campaign" : "Survey",
          )
        }

        Apxor.logEvent(
          "CampaignCreated",
          {
            name: meta?.name || "NA",
            actionType: meta.config_type,
            experience_type: campaignConfig?.meta?.experience_type ?? "",
          },
          APXOR_SDK_CATEGORY_CONSTANTS.CAMPAIGNS,
        )

        const newCampaignId = response.id
        push({
          pathname: `/apps/${auth?.appId}/${getRouteForActionType(
            actionType,
          )}/${newCampaignId}/edit`,
          state: {
            isDuplicated: true,
          },
        })
        const indexes = []
        const signedURLPromises = []
        others.uis.forEach((ui, index) => {
          if (ui.blobName && ui.blobName !== "") {
            indexes.push(index)
            signedURLPromises.push(
              getSignedURLToGetImage(auth, appId, {
                campaignId: _id,
                provider: ui.provider,
                blobName: ui.blobName,
              }),
            )
          }
        })

        if (signedURLPromises.length < 1) {
          setPending(false)
          refreshCampaigns()
          return
        }

        Promise.all(signedURLPromises)
          .then((values) => {
            const imagePromises = values.map((response) => {
              return axios.get(response.signed_url, {
                responseType: "arraybuffer",
              })
            })

            Promise.all(imagePromises)
              .then((images) => {
                const base64Images = []
                const uploadImageURLPromises = images.map((image) => {
                  base64Images.push(
                    Buffer.from(image.data, "binary").toString("base64"),
                  )
                  return getSignedURLToPost(auth, appId, {
                    appId: appId,
                    campaignId: newCampaignId,
                  })
                })

                Promise.all(uploadImageURLPromises).then((responses) => {
                  responses.forEach((urlResponse, index) => {
                    const { signed_url, provider, blob_name } = urlResponse
                    newConfig.uis[indexes[index]].provider = provider
                    newConfig.uis[indexes[index]].blobName = blob_name

                    const blob = base64ToBlob(base64Images[index], "image/png")
                    const file = new File([blob], "image.png")
                    uploadImageUsingSignedURL(signed_url, file)
                  })

                  updateCampaignAPI(
                    auth,
                    appId,
                    { configType: meta.config_type },
                    {
                      ...newConfig,
                      _id: newCampaignId,
                    },
                    newCampaignId,
                  ).then(() => {
                    setPending(false)
                    refreshCampaigns()
                  })
                })
              })
              .catch(() => {
                setPending(false)
                refreshCampaigns()
              })
          })
          .catch(() => {
            setPending(false)
            refreshCampaigns()
          })
      })
    })
    setAnchorEl(null)
  }

  async function publishCampaign(campaignConfig) {
    try {
      if (isExperienceDashboard()) {
        const [
          { messages: activeCampaigns },
          { messages: activeSurveys },
        ] = await Promise.all([
          getCampaignsAPI(
            auth,
            DEMO_ECOMMERCE_APP_ID,
            {
              configType: "walkthrough",
              kind: "regular",
              state: "ACTIVE",
              limit: 10000,
            },
            false,
          ),
          getCampaignsAPI(
            auth,
            DEMO_ECOMMERCE_APP_ID,
            {
              configType: "survey",
              kind: "regular",
              state: "ACTIVE",
              limit: 10000,
            },
            false,
          ),
        ])

        const activeCampaignsAndSurveys = [...activeCampaigns, ...activeSurveys]
        const convertToPausedPromises = activeCampaignsAndSurveys.map((tmpl) =>
          toggleCampaignAPI(auth, DEMO_ECOMMERCE_APP_ID, tmpl._id, {
            configType: tmpl?.meta?.config_type,
            enableStatus: false,
          }),
        )
        await Promise.all(convertToPausedPromises)
      }
      setPublishPending(true)
      const app_id = isExperienceDashboard()
        ? DEMO_ECOMMERCE_APP_ID
        : auth.appId

      const response = await publishCampaignAPI(
        auth,
        app_id,
        campaignConfig,
        isExperienceDashboard(),
      )
      if (!response) {
        return
      }
      setPublishPending(false)
      setShowPublishConfirmDialog(false)

      if (campaignConfig.meta.experience_type) {
        demoSlackMessager(
          auth.user,
          "Guided Published",
          campaignConfig?.meta?.name,
          campaignConfig.config_type === "walkthrough" ? "Campaign" : "Survey",
        )
      } else {
        demoSlackMessager(
          auth.user,
          "Self Published",
          campaignConfig?.meta?.name,
          campaignConfig.config_type === "walkthrough" ? "Campaign" : "Survey",
        )
      }

      Apxor.logEvent(
        "PublishCampaign",
        {
          name: campaignConfig?.meta?.name || "NA",
          actionType: actionType,
          experience_type: campaignConfig?.meta?.experience_type ?? "",
        },
        APXOR_SDK_CATEGORY_CONSTANTS.CAMPAIGNS,
      )
      push(`/apps/${app_id}/${getRouteForActionType(actionType)}`, {
        isExperiment: campaignConfig?.isExperiment,
        postPublish: true,
        campaignId: campaignConfig?._id,
      })
    } catch (error) {
      setPublishPending(false)
    }
  }

  return {
    completeConfig,
    setCompleteConfig,
    progressState,
    setProgressState,
    showABManualPubishDialog,
    setShowABManualPubishDialog,
    publishPending,
    setPublishPending,
    showPublishConfirmDialog,
    setShowPublishConfirmDialog,
    validationRef,
    validationMessage,
    setValidationMessage,
    anchorEl,
    setAnchorEl,
    pending,
    setPending,
    open,
    state,
    setState,
    handleDuplicate,
    deleteCampaign,
    toggleCampaign,
    publishCampaign,
    handleShowCampaignInfo,
    handleSnackbarClosed,
    fetchConfig,
  }
}

