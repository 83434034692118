import {
  Button,
  Grid,
  TextareaAutosize,
  Typography,
  withStyles,
} from "@material-ui/core";
import Apxor from "apxor";
import React, { useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { isExperienceDashboard } from "../../../config";
import { APXOR_SDK_CATEGORY_CONSTANTS } from "../../../constants";
import { useAuth } from "../../../contexts/AuthContext";
import { isValidEmail } from "../../../utils";
import { useDeepCompareCallback } from "../../../utils/use-deep-compare";
import ActionsTextField from "../../ReusableComponents/ActionsTextField";
import ColumnDiv from "../../ReusableComponents/ColumnDiv";
import CustomMaterialUIDialog from "../../ReusableComponents/CustomMaterialUIDialog";
import RowDiv from "../../ReusableComponents/RowDiv";
import { apxTheme } from "../styling/CustomCSS";
import { demoSlackContactMessager, sendContactUsMessage } from "./actions";

const StyledLabel = withStyles({
  root: {
    fontSize: "14px",
    fontWeight: "500",
    color: "#667A8A",
    marginBottom: "6px",
  },
})(Typography);

export default function ContactUsDialog({
  open,
  onClose,
  setContactUsMessage,
}) {
  const auth = useAuth();
  const { appId } = useParams();
  const { pathname } = useLocation();
  const path_arr = pathname.split("/");
  const nudge_id = ["edit", "analysis"].some((x) => path_arr.at(-1) === x)
    ? path_arr.at(-2)
    : "";

  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const submitHandler = useDeepCompareCallback(
    async (e) => {
      e.preventDefault();

      const res = await sendContactUsMessage(
        auth,
        {
          name,
          phone,
          email,
          message,
        },
        {
          channel: isExperienceDashboard() ? "sales" : "csm",
        },
      );

      if (res.status === "success") {
        Apxor.logEvent(
          "ContactUsSubmitted",
          {
            "nudge_id": nudge_id,
            "person_name": name,
            "person_mail": email,
            "person_phone": phone,
            "message": message,
            "app_id": appId,
          },
          APXOR_SDK_CATEGORY_CONSTANTS.CAMPAIGNS,
        );
        setContactUsMessage("Your message has been sent successfully!");
        // call slack messager
        demoSlackContactMessager(auth.user, {
          name,
          phone,
          email,
          message,
        })
      } else {
        setContactUsMessage(
          "There was some problem registering your query, kindly try mailing us.",
        );
      }
      setName("");
      setPhone("");
      setEmail("");
      setMessage("");
      onClose();
    },
    [
      appId,
      auth,
      email,
      message,
      name,
      nudge_id,
      onClose,
      phone,
      setContactUsMessage,
      demoSlackContactMessager
    ],
  );

  const EMAIL_ID = isExperienceDashboard()
    ? "sales@apxor.com"
    : "alchemy@apxor.com";

  return (
    <CustomMaterialUIDialog
      openFlag={open}
      onClose={onClose}
      onDialogClose={onClose}
      isFullWidth={false}
      noTitleBg
      maxWidth="sm"
      dialogContent={
        <ColumnDiv styles={{ padding: "26px", paddingTop: "0px" }}>
          <Typography
            style={{
              fontSize: "25px",
              fontWeight: "700",
              color: apxTheme.palette.primary.header,
              marginBottom: "16px",
            }}
          >
            Contact Us
          </Typography>

          <Typography
            style={{
              fontSize: "14px",
              fontWeight: "500",
              color: apxTheme.palette.primary.header,
              marginBottom: "40px",
            }}
          >
            Please let us know your concern by filling the following form or by
            emailing us at{" "}
            <a
              href={`mailto:${EMAIL_ID}`}
              style={{
                color: apxTheme.palette.primary.main,
                fontWeight: "700",
              }}
            >
              {EMAIL_ID}
            </a>
            . We will try to revert back to you within 24 hrs.
          </Typography>

          <form onSubmit={submitHandler}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <label>
                  <StyledLabel>Name</StyledLabel>
                  <ActionsTextField
                    focused
                    multiline={false}
                    type="text"
                    name="name"
                    margin="normal"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    InputProps={{
                      style: {
                        fontSize: "15px",
                        fontWeight: "600",
                        padding: "6px",
                        borderRadius: "2px",
                        borderColor: "#CCD4DA",
                      },
                    }}
                  />
                </label>
              </Grid>
              <Grid item xs={6}>
                <label>
                  <StyledLabel>Phone</StyledLabel>
                  <ActionsTextField
                    multiline={false}
                    type="text"
                    name="name"
                    margin="normal"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    InputProps={{
                      style: {
                        fontSize: "15px",
                        fontWeight: "600",
                        padding: "6px",
                        borderRadius: "2px",
                        borderColor: "#CCD4DA",
                      },
                    }}
                  />
                </label>
              </Grid>
              <Grid item xs={12}>
                <label>
                  <StyledLabel>Email</StyledLabel>
                  <ActionsTextField
                    multiline={false}
                    type="text"
                    name="name"
                    margin="normal"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    InputProps={{
                      style: {
                        fontSize: "15px",
                        fontWeight: "600",
                        padding: "6px",
                        borderRadius: "2px",
                        borderColor: "#CCD4DA",
                      },
                    }}
                  />
                </label>
              </Grid>
              <Grid item xs={12}>
                <label>
                  <StyledLabel>Your Message</StyledLabel>
                  <TextareaAutosize
                    minRows={6}
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    style={{
                      minWidth: "100%",
                      color: "#002845",
                      fontFamily: "Manrope, sans-serif",
                      fontSize: "15px",
                      fontWeight: "600",
                      padding: "12px",
                      borderRadius: "2px",
                      borderColor: "#CCD4DA",
                    }}
                  />
                </label>
              </Grid>
            </Grid>

            <RowDiv
              center
              styles={{
                gap: "15px",
                marginTop: "40px",
                justifyContent: "flex-end",
              }}
            >
              <Button variant="text" size="large" onClick={onClose}>
                Cancel
              </Button>
              <Button
                variant="contained"
                size="large"
                color="primary"
                type="submit"
                disabled={!name || !email || !message || !isValidEmail(email)}
              >
                Send Message
              </Button>
            </RowDiv>
          </form>
        </ColumnDiv>
      }
      dialogActions={<></>}
    />
  );
}
