import { Box, Button, CircularProgress, Typography } from "@material-ui/core";
import React from "react";
import { useAuth } from "../../../../../../contexts/AuthContext";
import CustomMaterialUIDialog from "../../../../../ReusableComponents/CustomMaterialUIDialog";
import { demoSlackMessagerIntegrate } from "../../actions";

export default function WelcomeBackDialog({
  name,
  open,
  onClose,
  demoPeriodDaysLeft,
}) {
  const auth = useAuth()

  return (
    <CustomMaterialUIDialog
      openFlag={open}
      onClose={onClose}
      onDialogClose={onClose}
      isFullWidth={false}
      maxWidth="sm"
      dialogContent={
        <Box
          style={{
            maxWidth: "450px",
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
            justifyContent: "center",
            color: "#002845",
            padding: "28px 22px",
            paddingTop: "0px",
          }}
        >
          <Typography
            style={{
              fontWeight: "700",
              fontSize: "25px",
              marginBottom: "29px",
            }}
          >
            Welcome back{name !== "" ? `, ${name}` : ``}!
          </Typography>
          <Box
            style={{
              minWidth: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
              backgroundColor: "#f9f9f9",
              borderRadius: "4px",
              padding: "20px 24px",
              marginBottom: "16px",
            }}
          >
            <Box
              style={{
                position: "relative",
                padding: "17px",
              }}
            >
              <CircularProgress
                value={100}
                variant="determinate"
                size={38}
                style={{
                  position: "absolute",
                  top: "0px",
                  left: "0px",
                  color: "#D9D9D9",
                }}
              />
              <CircularProgress
                color="primary"
                value={Math.ceil((demoPeriodDaysLeft / 7) * 100)}
                variant="determinate"
                size={38}
                style={{
                  position: "absolute",
                  top: "0px",
                  left: "0px",
                  zIndex: "10",
                }}
              />
            </Box>
            <Box
              style={{
                marginLeft: "17px",
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
              }}
            >
              <Typography
                style={{
                  fontWeight: "700",
                  fontSize: "19px",
                }}
              >
                {demoPeriodDaysLeft} out of 7 days left
              </Typography>
              <Typography
                style={{
                  opacity: "0.6",
                  fontSize: "14px",
                  fontWeight: "500",
                }}
              >
                in demo period
              </Typography>
            </Box>
          </Box>
          <Typography
            style={{
              fontWeight: "500",
              fontSize: "14px",
            }}
          >
            Try nudges on your app by integrating Apxor. Avail our 14 days free
            trail now!
          </Typography>
        </Box>
      }
      dialogActions={
        <Box
          style={{
            maxWidth: "430px",
            display: "flex",
            alignItems: "center",
            padding: "28px 36px",
            paddingTop: "0px",
          }}
        >
          <Button
            color="primary"
            variant="text"
            size="large"
            onClick={onClose}
            style={{
              marginRight: "20px",
              fontWeight: "800",
              fontSize: "16px",
              padding: "11px 18px",
            }}
          >
            Skip
          </Button>
          <a
            href="https://www.apxor.com/sign-up"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={()=>{ demoSlackMessagerIntegrate(auth.user) }}
              style={{
                fontWeight: "700",
                fontSize: "16px",
                padding: "11px 18px",
              }}
            >
              Integrate My App
            </Button>
          </a>
        </Box>
      }
    />
  );
}
